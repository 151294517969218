<template>
  <div class="pagination" v-show="pageCount > 1">
    <div class="left">
      <button
        :class="{ next: true, spill: currentSize == 1 }"
        @click="currentSize == 1 ? false : currentSize--"
      >
        上一页
      </button>
      <button
        :class="{ btn: true, active: currentSize == 1 }"
        @click="changeCurrent(1)"
      >
        {{ 1 }}
      </button>
      <!-- 当页面数大于连续页面数且当前页面超过某一个页时 -->
      <button
        class="btn"
        v-show="
          pageCount > pagerCount &&
          this.currentSize > Math.ceil(this.pagerCount / 2)
        "
      >
        ...
      </button>

      <!-- 下面和上面不存在出现 -->
      <template
        v-if="
          !(
            pageCount > pagerCount &&
            this.currentSize > Math.ceil(this.pagerCount / 2)
          )
        "
      >
        <button
          v-show="item > 1"
          :class="{ btn: true, active: currentSize == item }"
          v-for="(item, i) in pageCount < pagerCount
            ? pageCount
            : pagerCount - 1"
          :key="i"
          @click="changeCurrent(item)"
        >
          {{ item }}
        </button>
      </template>

      <!-- 当页面数大于连续页面数且当前页面超过某一个页时 -->
      <template
        v-if="
          pageCount > pagerCount &&
          this.currentSize > Math.ceil(this.pagerCount / 2)
        "
      >
        <button
          v-show="item >= spillStart"
          :class="{ btn: true, active: currentSize == item }"
          v-for="(item, i) in spillEnd"
          :key="i"
          @click="changeCurrent(item)"
        >
          {{ item }}
        </button>
      </template>
      <button
        class="btn"
        v-show="
          pageCount > pagerCount &&
          !(currentSize > Math.floor(pageCount - pagerCount / 2))
        "
      >
        ...
      </button>
      <button
        :class="{ btn: true, active: currentSize == pageCount }"
        v-show="pageCount >= pagerCount"
        @click="changeCurrent(pageCount)"
      >
        {{ pageCount }}
      </button>
      <button
        :class="{ next: true, spill: currentSize == pageCount }"
        @click="currentSize == pageCount ? false : currentSize++"
      >
        下一页
      </button>
    </div>
    <div class="right">
      <div>
        <span>到第</span>
        <input class="inp" v-model.number="num" />
        <span>页</span>
      </div>
      <button
        @mousedown="btnActive = true"
        @mouseup="btnActive = false"
        :class="{ btnActive }"
        @click="
          num <= pageCount ? (currentSize = num) : (currentSize = pageCount)
        "
      >
        确定
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["total", "pageSize", "currentSize", "pagerCount"],
  data() {
    return {
      num: null,
      btnActive: false,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  methods: {
    changeCurrent(item) {
      this.currentSize = item;
    },
  },
  watch: {
    currentSize(newSize, oldSize) {
      // 当页面数量大于连续页面时
      if (this.pageCount > this.pagerCount) {
        if (this.currentSize > Math.ceil(this.pagerCount / 2)) {
          this.spillEnd =
            this.currentSize + Math.floor((this.pagerCount - 2) / 2);
          if (this.spillEnd > this.pageCount - 1) {
            this.spillEnd = this.pageCount - 1;
          }
          this.spillStart =
            this.spillEnd - Math.floor((this.pagerCount - 2) / 2) * 2;
        }
      }
      this.$emit("update:currentSize", this.currentSize);
      this.$emit("changeSize", newSize, oldSize);
      this.num = null;
    },
  },
};
</script>

<style scoped lang="less">
.pagination {
  width: 83.3333vw;
  display: flex;
  height: 2.0833vw;
  .left {
    flex: 1;
    .previous,
    .next {
      background-color: #ffffff;
      width: 4.1667vw;
      height: 2.0833vw;
      border-radius: 1.5625vw;
      color: #696969;
      border: 0.1042vw solid #e1e1e1;
    }
    .spill {
      background-color: #c5c9d0;
    }
    .btn {
      background-color: #ffffff;
      width: 2.0833vw;
      height: 2.0833vw;
      border-radius: 100%;
      color: #696969;
      border: 0.1042vw solid #e1e1e1;
      margin: 0 0.5208vw;
    }
    .active {
      color: #f4d3d4;
      background-color: #d7000f;
    }
  }
  .right {
    width: 10.4167vw;
    display: flex;
    span {
      color: #535353;
    }
    input.inp {
      border-radius: 1.0417vw;
      width: 3.125vw;
      height: 2.0833vw;
      text-align: center;
      border: 0.0521vw solid #d7d7d7;
      margin: 0 0.2604vw;
    }
    button {
      background-color: #ffffff;
      border-radius: 1.0417vw;
      width: 3.125vw;
      height: 2.0833vw;
      text-align: center;
      border: 0.0521vw solid #d7d7d7;
      margin: 0 0.2604vw;
    }
    .btnActive {
      border: 0.0521vw solid #ec4552;
      color: #eb3e4c;
    }
  }
}
</style>
