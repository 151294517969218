<template>
    <div>
        <div class="img">
            <img src="./新闻资讯/1.png" alt="" />
        </div>
        <div class="zou"  >
            <div class="han">新闻资讯</div>
            <div class="ying">
                News
                information
            </div>
        </div>
        <div class="con">
            <el-tabs v-model="categoryId">
                <el-tab-pane
                        v-for="item in newsCategory"
                :key="item.id"
                :label="item.name"
                :name="item.id.toString()"
                ></el-tab-pane>
            </el-tabs>
            <NewsItem :cateid="categoryId"></NewsItem>
        </div>
    </div>
</template>
<script>
import NewsItem from "./NewsItem";
import axios from "axios";
export default {
    name: "News",
    components: { NewsItem },
  mounted() {
      this.$bus.$on("updateId",this.updateId)
  },
  methods: {
      updateId(id) {
        console.log(id)
        this.categoryId = (id+1).toString();
        console.log("我是id,",this.categoryId)
      }
  },
    data() {
        return {
            newsCategory: [],
            categoryId: "1",
        };
    },
    async created() {
        let newsCategory = await axios({
            url: "http://dwj.test.jianong.com/index.php/index/index/getNewsCategoty",
            post: "get",
            params: {},
        });
        if (newsCategory.status == 200) {
            this.newsCategory = newsCategory.data.data;
        }
    },
};
</script>

<style lang="less" scoped>
    .img img {
        width: 100%;
        height: 48.6891vh;
    }
    .zou{
        width: 11.1979vw;
        height: 11.551vh;
        opacity: 0.8;
        background-color: #d7000f;
        position: absolute;
        margin-left: 5.5625vw;
        margin-top: -8.53vh;
    }
    .han{
        width: 10.7083vw;
        height: 1.4063vw;
        font-size: 1.5625vw;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: left;
        color: #ffffff;
        margin-top:0.771vw ;
        margin-left: 1.1vw;
    }
    .ying{
        width: 10.125vw;
        height: 1.1979vw;
        font-size: 1.3vw;
        font-family: PingFang SC, PingFang SC-Light;
        font-weight: 300;
        color: #ffffff;
        margin-left: 1.1vw;
        margin-top: 0.65vw;
        line-height: 1.15vw;
    }


/deep/ .el-tabs {
    background-color: #fff;
    .el-tabs__header {
        margin: 0;
    }
    .el-tabs__nav-scroll {
        .el-tabs__nav {
            left: 50%;
            transform: translateX(-50%) !important;
        }
    }
    .el-tabs__item.is-active,
    .el-tabs__item:hover {
        color: #d7000f;
        font-size: 1.2vw;
    }
    .el-tabs__item {
        color: #646464;
        font-size: 1.2vw;
    }
    .el-tabs__active-bar {
        background-color: #d7000f;
        bottom: 0.1042vw;
    }
}
.con {
    background-color: #efefef;
    border-bottom: 1.5625vw solid #efefef;
}
</style>
