<template>
  <div class="container" ref="container">
    <header class="title" v-for="(item2, index) in newsList.cate">
      {{ item2.name }}
    </header>
    <div class="Etitle" v-for="(item2, index) in newsList.cate">
      {{ item2.title }}
    </div>

    <div class="context">
      <!--//以后如果需要给绑定的事件传参，在dom结构里传，就像这样子-->
      <div
        class="item"
        @click="btnClick(item.link, i, item)"
        v-for="(item, i) in newsList.data"
        :key="i"
      >
        <div class="left">
          <div class="monthDay">
            {{ item.add_time.split("-")[1] }}
            <!--{{ item.add_time.split("-")[2] }}-->

            {{ item.add_time.split("-")[2].split(" ")[0] }}
          </div>
          <div class="year">
            <span class="l"></span>
            {{ item.add_time.split("-")[0] }}
            <span class="r"></span>
          </div>
        </div>
        <div class="center">
          <header class="header">{{ item.name }}</header>
          <!--<p class="con" v-html="item.content.split(0,4)">{{item.content}}</p>-->
          <!--<p class="con" v-html="item.content"></p>-->
          <p class="con" v-html="delHtmlTag(item.messages)">
            {{ item.messages }}
          </p>
        </div>
        <div class="right">
          <img :src="item.pic" alt="" />
        </div>
      </div>
    </div>
    <Pagination
      :total="total"
      :pageSize="pageSize"
      :currentSize.sync="currentSize"
      :pagerCount="pagerCount"
      @changeSize="changeSize"
    ></Pagination>
  </div>
</template>
<script>
// 父传子用了props，子想改变父亲数据并且想让父亲那边监听数据，子再传数据，用自定义绑定事件
import axios from "axios";
import Pagination from "./Pagination";
export default {
  props: ["cateid"],
  name: "NewsItem",
  data() {
    return {
      newsList: {},
      total: 0,
      pageSize: 10,
      currentSize: 1,
      pagerCount: 7,
      link: "",
    };
  },
  async created() {
    // console.log(this.cateid + "hello");
    let newsList = await axios({
      url: "http://dwj.test.jianong.com/index.php/index/index/getNewsList",
      post: "get",
      params: {
        cateid: this.cateid,
        limit: this.pageSize,
        page: this.currentSize,
      },
    });
    if (newsList.status == 200) {
      this.newsList = newsList.data.data;
      this.total = this.newsList.total;
    }
    // console.log(this.newsList);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
  computed: {},
  components: {
    Pagination,
  },
  methods: {
    delHtmlTag(str) {
      // if (str != null || str == undefined  ) {
      //   console.log(str.replace(/<[^>]+>/g, ""))
      //   return str.replace(/<[^>]+>/g, "");
      if (str != null || str == undefined) {
        let estr = str
          // .replace(/<[^>]+>/g, "")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&amp;/g, "")
          .replace(/&nbsp;/g, "")
          .replace(/nbsp;/g, "")
            .replace(/&quot;/g, "'")
        return estr;
      } else {
      }
    },
    // 当前页码一旦改变就会触发这个函数
    async changeSize(newPage, oldPage) {
      let newsList = await axios({
        url: "http://dwj.test.jianong.com/index.php/index/index/getNewsList",
        post: "get",
        params: {
          cateid: this.cateid,
          limit: this.pageSize,
          page: this.currentSize,
        },
      });
      if (newsList.status == 200) {
        this.newsList = newsList.data.data;
        this.total = this.newsList.total;
      }
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    },
    //  传参就一定要有形参接参，就像下面一样
    btnClick(link, i, item) {
      // console.log(link);
      if (link == null || link == "") {
        const routeData = this.$router.resolve({
          // name: "juti",
          path:'/juti',
          // query: { key: i, cateid: this.cateid, item: JSON.stringify(item) },
        });
        window.open(routeData.href, "_blank");
        window.localStorage.setItem('item', JSON.stringify(item));
      } else {
        window.open(item.link, '_blank');
      }
    },
  },
  watch: {
    // 切换id就重新发送请求
    cateid: {
      async handler(newId, oldId) {
        this.currentSize = 1;
        let newsList = await axios({
          url: "http://dwj.test.jianong.com/index.php/index/index/getNewsList",
          post: "get",
          params: {
            cateid: this.cateid,
            limit: this.pageSize,
            page: this.currentSize,
          },
        });
        if (newsList.status == 200) {
          this.newsList = newsList.data.data;
          this.total = this.newsList.total;
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
/deep/p {
  margin: 0px;
  padding: 0px;
  outline: 0px;
  max-width: 100%;
  box-sizing: border-box !important;
  overflow-wrap: break-word !important;
  font-size: 0.8333vw !important;
  line-height: 1.0417vw !important;
  font-family: 宋体 !important;
  color: #b0b0b0 !important;
}
/deep/span {
  padding: 0px;
  outline: 0px;
  max-width: 100%;
  box-sizing: border-box !important;
  overflow-wrap: break-word !important;
  font-size: 0.8333vw !important;
  line-height: 1.0417vw !important;
  font-family: 宋体 !important;
  color: #b0b0b0 !important;
}
/deep/section {
  padding: 0px;
  outline: 0px;
  max-width: 100%;
  box-sizing: border-box !important;
  overflow-wrap: break-word !important;
  font-size: 0.8333vw !important;
  line-height: 1.0417vw !important;
  font-family: 宋体 !important;
  color: #b0b0b0 !important;
}

.pagination {
  margin: 1.5625vw auto;
  width: 72.9167vw;
}
.container {
  overflow: hidden;
  position: relative;
  margin: 1.5625vw auto;
  margin-bottom: 0;
  width: 83.3333vw;
  background-color: #fff !important;
  box-shadow: 0 0 0.0521vw 0.0521vw rgba(200, 200, 200, 1);
  header.title,
  div.Etitle {
    text-align: center;
    color: #8a8a8a;
    font-size: 1.3021vw;
  }
  header.title {
    color: #d7000f;
    font-size: 2.0833vw;
    margin-top: 3.6458vw;
    margin-bottom: 1.0417vw;
  }
  div.Etitle {
    margin-bottom: 2.6042vw;
  }
  .context {
    margin: 0 auto;
    width: 72.9167vw;
    .item {
      display: flex;
      padding: 3.125vw 0;
      border-bottom: 0.1042vw solid #ececec;
      .left {
        flex: 18;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .monthDay {
          color: #d7000f;
          font-size: 3.125vw;
          margin-bottom: 1.5625vw;
        }
        .year {
          color: #8c8c8c;
          font-size: 1.5625vw;
          line-height: 1.0417vw;
          display: flex;
          justify-content: center;
          align-items: center;
          span.l,
          span.r {
            width: 1.5625vw;
            height: 0.1042vw;
            background-color: #c6c6c6;
          }
          span.l {
            margin-right: 0.3208vw;
          }
          span.r {
            margin-left: 0.3208vw;
          }
        }
      }
      .center {
        width: 64%;
        padding: 0 2.0833vw;
        border-left: 0.1042vw solid #d9d9d9;
        header {
          margin: 1.0417vw 0;
          color: #000000;
          font-size: 1.5625vw;
          font-weight: 700;
          line-height: 2vw;
        }
        .con {
          height: 4.2vw;
          overflow: hidden;
          text-overflow: ellipsis;
          letter-spacing: 0.111vw;
          color: #b0b0b0;
          font-size: 0.955vw;
        }
      }
      .right {
        display: flex;
        align-items: center;
        flex: 18 !important;
        img {
          width: 12vw;
          height: 8vw;
        }
      }
    }
    /deep/.btn-prev {
      width: 15.625vw !important;
      height: 15.625vw !important;
    }
  }
}
.item:hover {
  cursor: pointer;
  /deep/ .center .header {
    color: red !important;
  }
}
</style>
