<template>
  <div>
    <index></index>
    <router-view></router-view>
    <bottom></bottom>
  </div>
</template>

<script>
import index from "../components/xinwen/index";
import Dao from "../components/common/Dao.vue";
import Bottom from "../components/common/Bottom.vue";
export default {
  name: "Xin",
  components: {
    Dao,
    Bottom,
    index,
  },
};
</script>

<style scoped></style>
